import { Button, Center, Loader, MantineProvider, Stack } from "@mantine/core";
import { IconVolume } from "@tabler/icons-react";
import anime from "animejs";
import { useEffect, useState } from "react";

import { type Assets, loadAssets } from "./assets";
import { SlotMachine } from "./SlotMachine";

type State = { assets: Assets; mode: "ready" | "starting" | "started" };

export function App() {
  const [state, setState] = useState<State>();

  // Load assets

  useEffect(() => {
    loadAssets()
      .then((assets) => setState({ assets, mode: "ready" }))
      .catch((error) => console.error("Cannot load assets", error));
  }, []);

  // Start the game

  function start() {
    if (!state) {
      return;
    }

    anime({
      targets: document.querySelector(".startButton"),
      translateX: 5_000,
      duration: 1_000,
      easing: "easeInSine",
      begin: () => {
        state.assets.sound("bubble2").play();
      },
      complete: () => {
        setState({ ...state, mode: "started" });
      },
    });

    anime({
      targets: document.querySelector(".soundIcon"),
      opacity: 0,
      easing: "linear",
      duration: 1_000,
    });
  }

  // Render

  return (
    <MantineProvider defaultColorScheme="dark">
      {state?.mode == "started" ? (
        <SlotMachine assets={state.assets} />
      ) : (
        <Center h="100vh">
          <Stack h="100%" justify="space-around" align="center">
            <IconVolume className="soundIcon" size={50} color="darkgrey" />
            <Loader
              size={"xl"}
              color="gray"
              style={{ visibility: state ? "hidden" : "visible" }}
            />
            <Button
              className="startButton"
              size="xl"
              variant="gradient"
              gradient={{
                from: "yellow",
                to: "rgba(255, 71, 71, 1)",
                deg: 109,
              }}
              disabled={!state}
              onClick={state?.mode == "ready" ? start : undefined}
            >
              {state ? "Ça part 🎲🎲" : "Ça charge"}
            </Button>
          </Stack>
        </Center>
      )}
    </MantineProvider>
  );
}
