import anime from "animejs";
import {
  createRef,
  forwardRef,
  type RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import {
  type Assets,
  type ImageAsset,
  type SoundAsset,
  type VideoAsset,
} from "./assets";
import { sample, windowHeight, windowWidth } from "./helpers";

const RAINBOW = ["#ff6666", "#ffbd55", "#ffff66", "#9de24f", "#87cefa"];

export type Celebration = (
  | { type: "rain"; symbols: string[]; itemCount?: number; itemScale?: number }
  | { type: "lines"; symbols: string[]; itemCount?: number; lineCount?: number }
  | {
      type: "burst";
      symbols: string[];
      itemScale?: number;
      burstCount?: number;
    }
  | { type: "video"; video: VideoAsset }
  | { type: "none" }
) & {
  text?: string[];
  sound?: SoundAsset;
  duration?: number;
};

type Item = {
  symbol: string;
  s: number;
  x: number;
  y: number;
  r: number;
  vx: number;
  vy: number;
  vr: number;
  physics: boolean;
};

let items: Item[] = [];

type Text = { lines: string[]; opacity: number; done?: true };

let texts: Text[] = [];

type Line = {
  canvas: HTMLCanvasElement;
  x: number;
  y: number;
  v: number;
};

let lines: Line[] = [];

export type CelebrationsHandle = {
  spawn: (celebration: Celebration) => void;
};

const CELEBRATION_DEFAULT_DURATION = 5_000;

const ITEM_SCALE_SPREAD = 0.6;

const RAIN_DEFAULT_SYMBOL_COUNT = 75;
const RAIN_MIN_SPEED = 400;
const RAIN_MAX_SPEED = 600;
const RAIN_MAX_RSPEED = 5;

const BURST_SYMBOL_COUNT = 25;
const BURST_DEFAULT_COUNT = 4;
const BURST_INTENSITY = 2_500;
const BURST_SPREAD = 0.7;
const BURST_PHYSICS_DECAY = 0.98;
const BURST_PHYSICS_RDECAY = 0.7;
const BURST_MAX_GRAVITY = 3_000;
const BURST_GRAVITY_INC = 3_000;
const BURST_MAX_RSPEED = 10;

const LINE_DEFAULT_COUNT = 6;
const LINE_DEFAULT_ITEM_COUNT = 20;
const LINE_SPACING = 0.25;
const LINE_ITEM_SPACING = 0.5;

export const Celebrations = forwardRef<CelebrationsHandle, { assets: Assets }>(
  (props, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const videoRefs = useRef<Record<VideoAsset, RefObject<HTMLVideoElement>>>({
      fire: createRef(),
      babybel: createRef(),
    });

    // Spawn new effects

    useImperativeHandle(
      ref,
      () => {
        return {
          spawn: (celebration: Celebration) => {
            // Main effect

            const duration =
              celebration.duration ?? CELEBRATION_DEFAULT_DURATION;

            if (celebration.type == "rain") {
              const verticalSpread =
                (duration * RAIN_MIN_SPEED) / 1000 - windowHeight();

              const itemCount =
                celebration.itemCount ?? RAIN_DEFAULT_SYMBOL_COUNT;

              for (let i = 0; i < itemCount; ++i) {
                items.push({
                  symbol: sample(celebration.symbols)!,
                  s:
                    (celebration.itemScale ?? 1) +
                    ITEM_SCALE_SPREAD * Math.random(),
                  x: Math.random() * windowWidth(),
                  y: -Math.random() * verticalSpread,
                  r: 0,
                  vx: 0,
                  vy:
                    RAIN_MIN_SPEED +
                    Math.random() * (RAIN_MAX_SPEED - RAIN_MIN_SPEED),
                  vr: -RAIN_MAX_RSPEED + Math.random() * 2 * RAIN_MAX_RSPEED,
                  physics: false,
                });
              }
            } else if (celebration.type == "burst") {
              const burstCount = celebration.burstCount ?? BURST_DEFAULT_COUNT;

              const delay = duration / burstCount;

              for (let i = 0; i < burstCount; ++i) {
                const left = i % 2 == 0;

                setTimeout(() => {
                  for (let i = 0; i < BURST_SYMBOL_COUNT; ++i) {
                    const intensity =
                      BURST_INTENSITY * 0.8 +
                      BURST_INTENSITY * 0.2 * Math.random();

                    const angle =
                      ((Math.random() * Math.PI) / 2) * BURST_SPREAD;

                    items.push({
                      symbol: sample(celebration.symbols)!,
                      s:
                        (celebration.itemScale ?? 1) +
                        ITEM_SCALE_SPREAD * Math.random(),
                      x: left ? 0 : windowWidth(),
                      y: windowHeight(),
                      r: Math.random(),
                      vx:
                        Math.cos(angle + Math.PI / 4) *
                        intensity *
                        (left ? 1 : -1),
                      vy: -Math.sin(angle + Math.PI / 4) * intensity,
                      vr:
                        -BURST_MAX_RSPEED +
                        Math.random() * 2 * BURST_MAX_RSPEED,
                      physics: true,
                    });
                  }
                }, delay * i);
              }
            } else if (celebration.type == "lines") {
              const lineCount = celebration.lineCount ?? LINE_DEFAULT_COUNT;

              const lineHeightWithSpacing = windowHeight() / lineCount;
              const lineSpacing = 1 - LINE_SPACING;
              const lineHeight = lineHeightWithSpacing * lineSpacing;

              const itemCount =
                celebration.itemCount ?? LINE_DEFAULT_ITEM_COUNT;

              const lineWidth =
                itemCount * lineHeight * (1 + LINE_ITEM_SPACING);

              const speed = (lineWidth + windowWidth()) / (duration / 1000);

              const lineCanvases: HTMLCanvasElement[] = [];

              for (let i = 0; i < lineCount; ++i) {
                const canvas = document.createElement("canvas");
                canvas.width = lineWidth;
                canvas.height = lineHeight;

                const ctx = canvas.getContext("2d");
                if (ctx) {
                  ctx.font = `${lineHeight}px serif`;
                  ctx.textAlign = "left";
                  ctx.textBaseline = "middle";

                  const symbol =
                    celebration.symbols[i % celebration.symbols.length];

                  const y = canvas.height / 2;

                  for (let j = 0; j < itemCount; ++j) {
                    const x = j * lineHeight * (1 + LINE_ITEM_SPACING);

                    if (symbol.startsWith("!")) {
                      const image = props.assets.image(
                        symbol.slice(1) as ImageAsset
                      );

                      const offset = lineHeight / 2;

                      ctx.drawImage(
                        image,
                        x,
                        y - offset,
                        lineHeight,
                        lineHeight
                      );
                    } else {
                      ctx.fillText(symbol, x, y);
                    }
                  }
                }

                lineCanvases.push(canvas);
              }

              for (let i = 0; i < lineCount; ++i) {
                const leftToRight = i % 2 == 0;

                lines.push({
                  canvas: lineCanvases[i % lineCanvases.length],
                  x: leftToRight ? -lineWidth : windowWidth(),
                  y: i * lineHeightWithSpacing + (lineHeight * lineSpacing) / 4,
                  v: leftToRight ? +speed : -speed,
                });
              }
            } else if (celebration.type == "video") {
              videoRefs.current[celebration.video].current?.play();

              anime
                .timeline({
                  complete: () => {
                    videoRefs.current[celebration.video].current?.pause();
                  },
                })
                .add({
                  targets: `.video-${celebration.video}`,
                  opacity: 1,
                  easing: "linear",
                  duration: 1_000,
                })
                .add({
                  duration: duration - 2_000,
                })
                .add({
                  targets: `.video-${celebration.video}`,
                  opacity: 0,
                  easing: "linear",
                  duration: 1_000,
                });
            }

            // Text

            if (celebration.text) {
              const text: Text = {
                lines: celebration.text,
                opacity: 0,
              };

              texts.push(text);

              anime
                .timeline({
                  complete: () => {
                    text.done = true;
                  },
                })
                .add({
                  targets: text,
                  opacity: 1,
                  easing: "linear",
                  duration: 500,
                })
                .add({
                  duration: duration - 1_000,
                })
                .add({
                  targets: text,
                  opacity: 0,
                  easing: "linear",
                  duration: 500,
                });
            }

            // Sound

            if (celebration.sound) {
              const sound = props.assets.sound(celebration.sound);
              sound.fade(0, 1, 500);
              sound.play();
            }
          },
        };
      },
      [props.assets]
    );

    // Rendering loop

    useEffect(() => {
      let frame = 0;

      let last = performance.now();

      function draw(time: DOMHighResTimeStamp) {
        const delta = time - last;
        const deltaS = delta / 1000;

        const symbolSize = Math.min(50, windowWidth() * 0.1);

        if (canvasRef.current) {
          canvasRef.current.width = windowWidth();
          canvasRef.current.height = windowHeight();

          const ctx = canvasRef.current.getContext("2d");
          if (ctx) {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // Items

            items.forEach((item) => {
              // Update

              item.x += item.vx * deltaS;
              item.y += item.vy * deltaS;
              item.r += item.vr * deltaS;

              if (item.physics) {
                item.vx -= item.vx * BURST_PHYSICS_DECAY * deltaS;
                item.vy -= item.vy * BURST_PHYSICS_DECAY * deltaS;

                item.vy = Math.min(
                  BURST_MAX_GRAVITY,
                  item.vy + BURST_GRAVITY_INC * deltaS
                );

                item.vr -= item.vr * BURST_PHYSICS_RDECAY * deltaS;
              }

              // Draw

              const x = item.x;
              const y = item.y;

              if (item.symbol.startsWith("!")) {
                const image = props.assets.image(
                  item.symbol.slice(1) as ImageAsset
                );

                ctx.save();
                ctx.translate(x, y);
                ctx.rotate(item.r);

                const s = symbolSize / image.width;
                ctx.scale(item.s * s, item.s * s);

                ctx.drawImage(image, -image.width / 2, -image.height / 2);
                ctx.restore();
              } else {
                ctx.font = `${symbolSize}px serif`;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";

                ctx.save();
                ctx.translate(x, y);
                ctx.rotate(item.r);
                ctx.scale(item.s, item.s);
                ctx.fillText(item.symbol, 0, 0);
                ctx.restore();
              }
            });

            items = items.filter(
              (item) =>
                item.y < windowHeight() + 50 ||
                item.x < -50 ||
                item.x > windowWidth() + 50
            );

            // Lines

            lines.forEach((line) => {
              // Update

              line.x += line.v * deltaS;

              // Draw

              ctx.drawImage(line.canvas, line.x, line.y);
            });

            lines = lines.filter((line) =>
              line.v > 0 ? line.x < windowWidth() : line.x > -line.canvas.width
            );

            // Texts

            const fontHeight = 35;
            const fontWidth = fontHeight * 0.6;

            const textCenterX = windowWidth() / 2;
            const textCenterY = (windowHeight() * 0.3) / 2;

            texts.forEach((text) => {
              let colorIndex = 0;

              const halfTextHeight = (text.lines.length * fontHeight) / 2;

              ctx.globalAlpha = text.opacity;

              text.lines.forEach((line, lineIndex) => {
                line.split("").forEach((char, charIndex) => {
                  ctx.font = `${fontHeight}px ${
                    props.assets.font("text").family
                  }`;
                  ctx.textAlign = "center";
                  ctx.textBaseline = "top";
                  ctx.fillStyle = RAINBOW[colorIndex % RAINBOW.length];

                  const x =
                    textCenterX + (-line.length / 2 + charIndex) * fontWidth;

                  const y =
                    textCenterY -
                    halfTextHeight +
                    lineIndex * fontHeight +
                    Math.sin((time + charIndex * 200) * 0.005) *
                      fontHeight *
                      0.1;

                  ctx.fillText(char, x, y);

                  if (char != " ") {
                    ++colorIndex;
                  }
                });
              });

              ctx.globalAlpha = 1;
            });
          }
        }

        texts = texts.filter((text) => !text.done);

        last = time;

        frame = requestAnimationFrame(draw);
      }

      frame = requestAnimationFrame(draw);

      return () => {
        cancelAnimationFrame(frame);
      };
    }, [props.assets]);

    // Render

    function video(asset: VideoAsset) {
      return (
        <video
          ref={videoRefs.current[asset]}
          className={`video-${asset}`}
          src={props.assets.video(asset).src}
          loop
          muted
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: "none",
            zIndex: -1,
            height: "100%",
            objectFit: "cover",
            opacity: 0,
          }}
        />
      );
    }

    return (
      <>
        {video("fire")}
        {video("babybel")}

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            zIndex: 9999,
          }}
        ></canvas>
      </>
    );
  }
);
