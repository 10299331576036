import type { Celebration } from "./Celebrations";

export type Prize = {
  symbol: string;
  combo: [string, string, string];
  celebrations?: Celebration[];
};

export const tinyPrizes: Prize[] = [
  {
    symbol: "🌻",
    combo: ["🌻", "🌻", "🌻"],
    celebrations: [
      {
        type: "rain",
        symbols: ["🌻", "🌼", "🌷"],
        itemCount: 75,
        text: ["Pluie", "de fleurs", "sur toi"],
      },
    ],
  },
  {
    symbol: "🔴",
    combo: ["🔴", "🔴", "🔴"],
    celebrations: [
      {
        type: "video",
        video: "babybel",
        text: ["5 millions", "par jour", "", "w o w"],
      },
      {
        type: "burst",
        symbols: ["🧀", "🔴"],
      },
    ],
  },
  {
    symbol: "🔥",
    combo: ["🔥", "🔥", "🔥"],
    celebrations: [
      {
        type: "video",
        video: "fire",
        sound: "fire",
        text: ["le feu"],
        duration: 7_000,
      },
      {
        type: "burst",
        symbols: ["🔥", "🧯"],
      },
    ],
  },
  {
    symbol: "🐸",
    combo: ["🐸", "🐸", "🐸"],
    celebrations: [
      {
        type: "rain",
        symbols: ["🐸", "🐸", "🔔"],
        text: ["B E M", "", "   B E M"],
        sound: "dingding",
        duration: 8_000,
      },
    ],
  },
  {
    symbol: "🛵",
    combo: ["🛵", "🛵", "🛵"],
    celebrations: [
      {
        type: "lines",
        itemCount: 15,
        lineCount: 8,
        symbols: ["🍝", "🛵", "🤌", "🍕"],
        text: ["buongiorno", "ragazza"],
        sound: "italia",
        duration: 8_000,
      },
    ],
  },
  {
    symbol: "🐈",
    combo: ["!gilbertHead", "!gilbertHead", "!gilbertHead"],
    celebrations: [
      {
        type: "rain",
        symbols: ["!gilbert1", "!gilbert2", "!gilbert3", "🦭"],
        itemScale: 3,
        text: ["Gilbert", "surprise"],
        sound: "justanOval1",
        duration: 9_500,
      },
      {
        type: "burst",
        symbols: ["!gilbertHead"],
        itemScale: 2,
        duration: 9_500,
      },
    ],
  },
  {
    symbol: "🐕",
    combo: ["!kafkaHead", "!kafkaHead", "!kafkaHead"],
    celebrations: [
      {
        type: "lines",
        lineCount: 3,
        itemCount: 5,
        symbols: ["!kafka1", "!kafka2"],
        text: ["Salut c'est", "Kafka"],
        sound: "justanOval2",
        duration: 7_500,
      },
    ],
  },
  // Last tiny prize: unlocked after everything else
  {
    symbol: "💘",
    combo: ["🅹", "🆃", "🅼"],
    celebrations: [
      {
        type: "burst",
        symbols: ["💌", "👄"],
        burstCount: 20,
        text: ["* s m a c k *"],
        duration: 10_000,
      },
      {
        type: "rain",
        symbols: ["👽"],
        itemCount: 50,
        duration: 9_000,
      },
    ],
  },
];

export type SuperPrize = Prize & {
  tries: number;
};

export const superPrizes: SuperPrize[] = [
  {
    symbol: "🍫",
    combo: ["🍫", "🍫", "🍫"],
    celebrations: [
      {
        type: "burst",
        symbols: ["🍫", "🍫", "🍫", "🍫", "🍫", "🍫", "🍫", "🍫", "👄", "🤢"],
        text: ["You win:", "Une boîte de", "Ferrero Rocher"],
      },
    ],
    tries: 6,
  },
  {
    symbol: "🍹",
    combo: ["🍹", "🍹", "🍹"],
    celebrations: [
      {
        type: "rain",
        symbols: ["🍹", "🍹", "🍊"],
        text: ["You win:", "Avalanche", "de", "Spritz"],
        duration: 6_000,
      },
    ],
    tries: 4,
  },
  {
    symbol: "💆",
    combo: ["🌶️", "💆", "🌶️"],
    celebrations: [
      {
        type: "burst",
        symbols: ["💆", "☁️", "👏"],
        text: ["You win:", "un doux massage"],
      },
    ],
    tries: 4,
  },
  {
    symbol: "🍴",
    combo: ["🍴", "🍲", "🍷"],
    tries: 5,
    celebrations: [
      {
        type: "burst",
        symbols: ["👄", "🧄", "🧄", "🧄"],
        text: ["You win:", "un dîner", "chez Tête d'Aïl"],
      },
    ],
  },
  {
    symbol: "🌊",
    combo: ["🧳", "🚙", "🌊"],
    tries: 5,
    celebrations: [
      {
        type: "burst",
        burstCount: 8,
        symbols: ["🐚", "🦪", "🦀", "🦞"],
        text: ["You win:", "   week-end", "      au bord", "de la mer"],
        duration: 8_000,
      },
    ],
  },
];
