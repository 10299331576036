import _ from "lodash";
import { z } from "zod";

const ProgressSchema = z.object({
  superPrizeTries: z.number(),
  totalTries: z.number(),
  tinyPrizeIndices: z.array(z.number()),
  superPrizeIndices: z.array(z.number()),
});

export type Progress = z.infer<typeof ProgressSchema>;

export function defaultProgress(): Progress {
  return {
    superPrizeTries: 0,
    totalTries: 0,
    tinyPrizeIndices: [],
    superPrizeIndices: [],
  };
}

const LOCAL_STORAGE_KEY = "slotMachineProgress";

export function saveProgress(progress: Progress) {
  console.info("Saving progress", progress);

  const cleanProgress = _.cloneDeep(progress);
  cleanProgress.tinyPrizeIndices = _.uniq(cleanProgress.tinyPrizeIndices);
  cleanProgress.superPrizeIndices = _.uniq(cleanProgress.superPrizeIndices);

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cleanProgress));
}

export function loadProgress(): Progress {
  console.info("Loading progress...");

  const maybeProgress = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (maybeProgress) {
    const progress = ProgressSchema.safeParse(JSON.parse(maybeProgress));

    if (progress.data) {
      console.info("Loaded progress", progress.data);
      return progress.data;
    } else {
      console.error("Cannot load progress", progress.error);
      return defaultProgress();
    }
  } else {
    console.info("No progress to load");
    return defaultProgress();
  }
}
