import { Center, MantineProvider } from "@mantine/core";
import { createRoot } from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";

import { OldScreen } from "./experiments/crt_shader/OldScreen";
import { App as SlotMachineApp } from "./experiments/slot_machine/App";
import { TextToEmojiEditor } from "./experiments/text_to_emoji/TextToEmojiEditor";
import { Home } from "./Home";

export const pages = {
  "crt-old-screen-shader": (
    <div style={{ width: "100vw", height: "100vh" }}>
      <OldScreen showControls />
    </div>
  ),
  "text-to-emoji-generator": <TextToEmojiEditor />,
  "👽👽": <SlotMachineApp />,
};

const router = createHashRouter([
  { path: "/", element: <Home />, errorElement: <ErrorPage /> },

  ...Object.entries(pages).map(([path, element]) => ({
    path,
    element,
  })),
]);

const container = document.getElementById("app");
if (container) {
  createRoot(container).render(<RouterProvider router={router} />);
}

// TODO strict mode

function ErrorPage() {
  return (
    <MantineProvider>
      <Center w="100vw" h="100vh">
        <h1>404 🍊</h1>
      </Center>
    </MantineProvider>
  );
}
