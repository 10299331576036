import "react-awesome-button/dist/styles.css";
import "./global.scss";

import {
  ActionIcon,
  Button,
  Center,
  Container,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  useMatches,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconRotate } from "@tabler/icons-react";
import anime from "animejs";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AwesomeButton } from "react-awesome-button";

import { type Assets } from "./assets";
import { Celebrations, type CelebrationsHandle } from "./Celebrations";
import { type Combo, prizeCombo, randomCombo } from "./Combo";
import { sample } from "./helpers";
import { type Prize, superPrizes, tinyPrizes } from "./Prize";
import {
  defaultProgress,
  loadProgress,
  type Progress,
  saveProgress,
} from "./Progress";
import { Reel, type ReelState, SYMBOLS } from "./Reel";

const ROLL_CYCLES = 10;
const ROLL_DURATION = 5_000;
const REEL_DELAY = 400;

const PROB_SUPER_PRIZE = 0.5;
const PROB_TINY_PRIZE = 0.4;

type State = {
  mode: "idle" | "rolling";
  progress: Progress;
  reels: { a: ReelState; b: ReelState; c: ReelState };
};

export function SlotMachine(props: { assets: Assets }) {
  // State

  const newGame = useCallback((reset: boolean): State => {
    return {
      mode: "idle",
      progress: reset ? defaultProgress() : loadProgress(),
      reels: {
        a: {
          position: 0,
        },
        b: {
          position: 3 / SYMBOLS.length,
        },
        c: {
          position: 6 / SYMBOLS.length,
        },
      },
    };
  }, []);

  const [state, setState] = useState<State>(() => newGame(false));

  // Intro animation

  useEffect(() => {
    const timeline = anime.timeline({});

    // Make prizes appear one after another

    const prizeElements = [...document.querySelectorAll(".prize")];

    prizeElements.forEach((element) => {
      timeline.add({
        targets: element,
        opacity: 1,
        duration: 150,
        easing: "linear",
        begin: () => {
          props.assets.sound("bubble1").play();
        },
      });
    });

    timeline.add({
      targets: document.querySelector(".reels"),
      translateX: 0,
      duration: 500,
      easing: "easeOutQuad",
      complete: () => {
        props.assets.sound("blip1").play();
      },
    });

    timeline.add({
      targets: document.querySelector(".button"),
      translateX: 0,
      duration: 500,
      easing: "easeOutSine",
      complete: () => {
        props.assets.sound("blip2").play();

        const ambience = props.assets.sound("casino");
        ambience.fade(0, 1, 2_000);
        ambience.play();

        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState == "hidden") {
            ambience.pause();
          } else {
            ambience.play();
          }
        });

        celebrationsRef.current?.spawn({
          type: "none",
          text: ["Salut Loreen"],
          duration: 5_000,
        });
      },
    });
  }, [props.assets]);

  // Save progress when it changes

  useEffect(() => {
    saveProgress(state.progress);
  }, [state.progress]);

  // Rolling loop

  const celebrationsRef = useRef<CelebrationsHandle>(null);

  function roll(riggedPrize: Prize | undefined) {
    setState((old) => ({
      ...old,
      mode: "rolling",
    }));

    let combo: Combo;

    // Rigged roll: force chance

    if (riggedPrize) {
      combo = prizeCombo(riggedPrize);
    } else {
      // Special case: unlocked everything except JTM, roll it now!

      const timeToUnlockJtm =
        !state.progress.tinyPrizeIndices.includes(tinyPrizes.length - 1) &&
        tinyPrizes
          .slice(0, -1)
          .every((prize, prizeIndex) =>
            state.progress.tinyPrizeIndices.includes(prizeIndex)
          ) &&
        superPrizes.every((prize, prizeIndex) =>
          state.progress.superPrizeIndices.includes(prizeIndex)
        );

      if (timeToUnlockJtm) {
        combo = prizeCombo(_.last(tinyPrizes)!);
      } else {
        // Enough tries for the next super prize?

        const nextSuperPrize =
          superPrizes[
            superPrizes.findIndex(
              (prize, prizeIndex) =>
                !state.progress.superPrizeIndices.includes(prizeIndex)
            )
          ];

        if (
          nextSuperPrize &&
          state.progress.superPrizeTries >= nextSuperPrize.tries &&
          Math.random() < PROB_SUPER_PRIZE // 1/2 chance of winning
        ) {
          combo = prizeCombo(nextSuperPrize);
        } else {
          // Otherwise, chance of winning a tiny prize
          // (after a few mandatory losses)

          const win =
            state.progress.totalTries >= 2 && Math.random() < PROB_TINY_PRIZE;

          if (win) {
            // Only pull JTM when unlocked

            const prizes = state.progress.tinyPrizeIndices.includes(
              tinyPrizes.length - 1
            )
              ? tinyPrizes
              : tinyPrizes.slice(0, -1);

            // Higher chance to pull new prizes

            const weightedPrizes: Prize[] = [];
            prizes.forEach((prize, prizeIndex) => {
              if (state.progress.tinyPrizeIndices.includes(prizeIndex)) {
                weightedPrizes.push(prize);
              } else {
                weightedPrizes.push(prize, prize, prize, prize);
              }
            });

            combo = prizeCombo(sample(weightedPrizes)!);
          } else {
            combo = randomCombo();
          }
        }
      }
    }

    console.debug("Next combo:", JSON.stringify(combo));

    // Start rolling

    const animatedReels = {
      a: state.reels.a.position,
      b: state.reels.b.position,
      c: state.reels.c.position,
    };

    let patched = false;

    const timeline = anime.timeline({
      duration: ROLL_DURATION,
      easing: "easeInOutElastic(0.1, 2)",
      update: (anim) => {
        // Update the reels position

        setState((old) => ({
          ...old,
          reels: {
            a: { ...old.reels.a, position: animatedReels.a },
            b: { ...old.reels.b, position: animatedReels.b },
            c: { ...old.reels.c, position: animatedReels.c },
          },
        }));

        // At the middle of the animation
        // - unpatch
        // - patch if needed

        if (!patched && anim.progress > 50) {
          // Unpatch

          setState((old) => ({
            ...old,
            reels: {
              a: {
                ...old.reels.a,
                patch: undefined,
              },
              b: {
                ...old.reels.b,
                patch: undefined,
              },
              c: {
                ...old.reels.c,
                patch: undefined,
              },
            },
          }));

          // Patch

          if (combo.prize) {
            setState((old) => ({
              ...old,
              reels: {
                a: {
                  ...old.reels.a,
                  patch: combo.reels.a,
                },
                b: {
                  ...old.reels.b,
                  patch: combo.reels.b,
                },
                c: {
                  ...old.reels.c,
                  patch: combo.reels.c,
                },
              },
            }));
          }

          patched = true;
        }
      },
      complete: () => {
        const prize = combo.prize;

        // Won a prize?
        if (prize) {
          if (prize.celebrations) {
            prize.celebrations.forEach((celebration) =>
              celebrationsRef.current?.spawn(celebration)
            );
          }

          // Super prize
          if ("tries" in prize) {
            props.assets.sound("win2").play();

            setState((old) => ({
              ...old,
              progress: {
                ...old.progress,
                // Next prize
                superPrizeTries: 0,
                // Save the prize
                superPrizeIndices: [
                  ...old.progress.superPrizeIndices,
                  superPrizes.indexOf(prize),
                ],
              },
            }));
          }
          // Tiny prize
          else {
            props.assets.sound("win1").play();

            setState((old) => ({
              ...old,
              progress: {
                ...old.progress,
                // Increment the tries
                superPrizeTries: old.progress.superPrizeTries + 1,
                // Save the prize
                tinyPrizeIndices: [
                  ...old.progress.tinyPrizeIndices,
                  tinyPrizes.indexOf(prize),
                ],
              },
            }));
          }
        } else {
          // Winning random combo?
          if (
            combo.reels.a.symbol == combo.reels.b.symbol &&
            combo.reels.b.symbol == combo.reels.c.symbol
          ) {
            celebrationsRef.current?.spawn({
              type: "burst",
              burstCount: 2,
              duration: 2_000,
              symbols: [
                combo.reels.a.symbol,
                combo.reels.b.symbol,
                combo.reels.c.symbol,
                "🎲",
                "🎲",
              ],
            });

            props.assets.sound("win1").play();
          }

          // Losing random combo?
          else {
            props.assets.sound("loss").play();
          }

          setState((old) => ({
            ...old,
            progress: {
              ...old.progress,
              // Increment the tries
              superPrizeTries: old.progress.superPrizeTries + 1,
            },
          }));
        }
        setState((old) => ({
          ...old,
          progress: {
            ...old.progress,
            // Increment the total tries
            totalTries: old.progress.totalTries + 1,
          },
        }));

        // Back to idle mode

        setState((old) => ({
          ...old,
          mode: "idle",
        }));
      },
    });

    function reel(r: "a" | "b" | "c", offset: number) {
      // Align with the start of the current cycle
      const start = Math.trunc(state.reels[r].position);

      let nextClick = start;

      timeline.add(
        {
          targets: animatedReels,
          [r]:
            start +
            // Add rools
            ROLL_CYCLES -
            // Minus the offset to the target symbol
            combo.reels[r].position,

          update: () => {
            // *clic clic clic*

            if (animatedReels[r] >= nextClick) {
              props.assets.sound("clic").play();
              nextClick += 0.75;
            }
          },

          complete: () => {
            // Final *clic*

            props.assets.sound("clic").play();
          },
        },
        offset
      );
    }

    reel("a", 0);
    reel("b", REEL_DELAY);
    reel("c", REEL_DELAY * 2);
  }

  const gameCompleted = useMemo(
    () =>
      tinyPrizes.every((prize, prizeIndex) =>
        state.progress.tinyPrizeIndices.includes(prizeIndex)
      ) &&
      superPrizes.every((prize, prizeIndex) =>
        state.progress.superPrizeIndices.includes(prizeIndex)
      ),
    [state.progress]
  );

  // Render

  const [newGameOpened, { open: openNewGame, close: closeNewGame }] =
    useDisclosure(false);

  const reelsWidth = useMatches({
    base: "80vw",
    sm: "400px",
  });

  return (
    <>
      <Center>
        <Stack w="100vw" h="100vh" align="center" gap="xl">
          {/* New Game? */}

          {gameCompleted && (
            <ActionIcon
              variant="outline"
              style={{ position: "fixed", top: "1em", right: "1em" }}
              onClick={openNewGame}
            >
              <IconRotate />
            </ActionIcon>
          )}

          {/* Empty space for the celebration text */}

          <Container h="30%"></Container>

          {/* Reels */}

          <Stack flex={1} w={reelsWidth} justify="center">
            <Group
              className="reels"
              gap="xs"
              justify="space-between"
              wrap="nowrap"
              style={{ position: "relative", transform: "translateX(-2000px)" }}
            >
              <Flex flex={1} w="30%">
                <Reel {...state.reels.a} assets={props.assets} />
              </Flex>
              <Flex flex={1} w="30%">
                <Reel {...state.reels.b} assets={props.assets} />
              </Flex>
              <Flex flex={1} w="30%">
                <Reel {...state.reels.c} assets={props.assets} />
              </Flex>

              {/* Middle dashed line */}

              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  border: "1px dashed rgba(0, 0, 0, 0.1)",
                }}
              ></div>
            </Group>

            <AwesomeButton
              className="button"
              type="danger"
              onPress={state.mode == "idle" ? () => roll(undefined) : undefined}
              style={{ transform: "translateX(2000px)" }}
            >
              <Text size="xl">🎲</Text>
            </AwesomeButton>
          </Stack>

          {/* Prizes */}

          <Stack h="30%" align="center" justify="center">
            <Prizes
              prizes={tinyPrizes}
              wonPrizeIndices={state.progress.tinyPrizeIndices}
              rollForPrize={roll}
              rolling={state.mode == "rolling"}
            />

            <Prizes
              prizes={superPrizes}
              wonPrizeIndices={state.progress.superPrizeIndices}
              rollForPrize={roll}
              rolling={state.mode == "rolling"}
            />
          </Stack>
        </Stack>
      </Center>

      <Celebrations ref={celebrationsRef} assets={props.assets} />

      <Modal
        opened={newGameOpened}
        onClose={closeNewGame}
        title="Nouvelle partie ? 👉👈"
      >
        <Center>
          <Group>
            <Button variant="transparent" onClick={closeNewGame}>
              Non
            </Button>
            <Button
              variant="transparent"
              onClick={() => {
                closeNewGame();
                setState(newGame(true));
              }}
            >
              Oui
            </Button>
          </Group>
        </Center>
      </Modal>
    </>
  );
}

function Prizes(props: {
  prizes: Prize[];
  wonPrizeIndices: number[];
  rollForPrize: (prize: Prize) => void;
  rolling: boolean;
}) {
  const buttonSize = useMatches({
    base: "5vw",
    md: "50px",
  });

  const iconSize = useMatches({
    base: "4.5vw",
    md: "40px",
  });

  return (
    <Group>
      {props.prizes.map((prize, prizeIndex) => {
        const won = props.wonPrizeIndices.includes(prizeIndex);

        return (
          <ActionIcon
            key={prizeIndex}
            className="prize"
            variant="transparent"
            w={buttonSize}
            h={buttonSize}
            style={{
              filter: won ? undefined : "brightness(0%) blur(2px)",
              cursor: won && !props.rolling ? "pointer" : "default",
              opacity: 0,
            }}
            onClick={
              won && !props.rolling
                ? () => props.rollForPrize(prize)
                : undefined
            }
          >
            <Text fz={iconSize} style={{ textShadow: "0 0 5px black" }}>
              {prize.symbol}
            </Text>
          </ActionIcon>
        );
      })}
    </Group>
  );
}
