import _ from "lodash";
import { useEffect, useRef } from "react";

import type { Assets, ImageAsset } from "./assets";

export const SYMBOLS = _.shuffle(["🍒", "🍌", "🍉", "🍇", "🍑", "🍍", "🥑"]);

export type ReelState = {
  position: number;
  patch?: { symbol: string; position: number };
};

export function Reel(props: ReelState & { assets: Assets }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let frame = 0;

    function draw() {
      if (canvasRef.current) {
        const availableWidth =
          canvasRef.current.parentElement?.clientWidth ?? 0;

        const symbolSize = availableWidth * 0.7;
        const symbolSpacing = availableWidth * 0.3;
        const symbolMargin = symbolSpacing / 2;

        canvasRef.current.width = availableWidth;
        canvasRef.current.height = symbolSize * 3 + symbolSpacing * 3;

        const fullHeight = (symbolSize + symbolSpacing) * SYMBOLS.length;

        const heightOffset =
          (fullHeight - canvasRef.current.height + symbolSize) / 2 / fullHeight;

        const ctx = canvasRef.current.getContext("2d");
        if (ctx) {
          // Clear

          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

          // Draw the main band + one before & one after to fill the gaps

          function drawBand(progress: number, ctx: CanvasRenderingContext2D) {
            SYMBOLS.forEach((symbol, symbolIndex) => {
              // Patch the current symbol

              let actualSymbol = symbol;

              if (
                props.patch &&
                Math.round(props.patch.position * SYMBOLS.length) == symbolIndex
              ) {
                actualSymbol = props.patch.symbol;
              }

              // Draw the symbol
              // - single char: emoji
              // - prefixed with "!": image name

              const x = symbolMargin + symbolSize / 2;

              const y =
                (symbolSize + symbolSpacing) * symbolIndex +
                progress * fullHeight;

              if (actualSymbol.startsWith("!")) {
                const image = props.assets.image(
                  actualSymbol.slice(1) as ImageAsset
                );

                ctx.drawImage(
                  image,
                  x - symbolSize / 2,
                  y,
                  symbolSize,
                  symbolSize
                );
              } else {
                ctx.font = `${symbolSize}px Arial`;
                ctx.textAlign = "center";
                ctx.textBaseline = "top";
                ctx.fillStyle = "plum";

                ctx.fillText(actualSymbol, x, y);
              }
            });
          }

          drawBand((props.position % 1) - 1 - 0.5 - heightOffset, ctx);
          drawBand((props.position % 1) - 0.5 - heightOffset, ctx);
          drawBand((props.position % 1) + 1 - 0.5 - heightOffset, ctx);
        }
      }

      frame = requestAnimationFrame(draw);
    }

    frame = requestAnimationFrame(draw);

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [props.assets, props.patch, props.position]);

  return (
    <canvas
      ref={canvasRef}
      //style={{ border: "1px solid black" }}
    ></canvas>
  );
}
