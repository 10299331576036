export function windowWidth(): number {
  return window.visualViewport?.width ?? window.innerWidth;
}

export function windowHeight(): number {
  return window.visualViewport?.height ?? window.innerHeight;
}

export function sample<T>(values: T[]): T {
  const random = new Uint32Array(1);
  window.crypto.getRandomValues(random);
  return values[Math.floor(random[0] % values.length)];
}
