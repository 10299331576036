import { sample } from "./helpers";
import type { Prize, SuperPrize } from "./Prize";
import { SYMBOLS } from "./Reel";

type Target = {
  symbol: string;
  position: number;
};

export type Combo = {
  reels: { a: Target; b: Target; c: Target };
  prize: Prize | SuperPrize | undefined;
};

export function randomCombo(): Combo {
  function pick(): Target {
    const symbol = sample(SYMBOLS)!;

    const position = SYMBOLS.indexOf(symbol) / SYMBOLS.length;

    return {
      symbol,
      position,
    };
  }

  const a = pick();
  const b = pick();
  const c = pick();

  return { reels: { a, b, c }, prize: undefined };
}

export function prizeCombo(prize: Prize): Combo {
  function set(index: number): Target {
    const symbol = prize.combo[index];

    const position =
      Math.floor(Math.random() * SYMBOLS.length) / SYMBOLS.length;

    return {
      symbol,
      position,
    };
  }

  const a = set(0);
  const b = set(1);
  const c = set(2);

  return { reels: { a, b, c }, prize };
}
