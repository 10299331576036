import { Howl } from "howler";
import babybelVideoPath from "url:./assets/babybel.mp4";
import blip1SoundPath from "url:./assets/blip1.wav";
import blip2SoundPath from "url:./assets/blip2.wav";
import bubble1SoundPath from "url:./assets/bubble1.mp3";
import bubble2SoundPath from "url:./assets/bubble2.wav";
import casinoSoundPath from "url:./assets/casino.mp3";
import clicSoundPath from "url:./assets/clic.mp3";
import dingdingSoundPath from "url:./assets/dingding.mp3";
import fireSoundPath from "url:./assets/fire.mp3";
import fireVideoPath from "url:./assets/fire.mp4";
import gilbert1ImagePath from "url:./assets/gilbert1.png";
import gilbert2ImagePath from "url:./assets/gilbert2.png";
import gilbert3ImagePath from "url:./assets/gilbert3.png";
import gilbertHeadImagePath from "url:./assets/gilberthead.png";
import italiaSoundPath from "url:./assets/italia.mp3";
import jacquardFontPath from "url:./assets/Jacquard12-Regular.ttf";
import justanOval1SoundPath from "url:./assets/justanoval1.mp3";
import justanOval2SoundPath from "url:./assets/justanoval2.mp3";
import kafka1ImagePath from "url:./assets/kafka1.png";
import kafka2ImagePath from "url:./assets/kafka2.png";
import kafkaHeadImagePath from "url:./assets/kafkaHead.png";
import lossSoundPath from "url:./assets/loss.mp3";
import win1SoundPath from "url:./assets/win1.mp3";
import win2SoundPath from "url:./assets/win2.mp3";

// Sound

async function loadSound(path: string, loop = false): Promise<Howl> {
  return new Promise((resolve, reject) => {
    const sound = new Howl({
      src: path,
      loop,
      onload: (soundId) => {
        console.log("Sound loaded", path, soundId);
        resolve(sound);
      },
      onloaderror: (soundId, error) => {
        console.error("Cannot load sound", path, soundId, error);
        reject(error);
      },
    });
  });
}

async function loadSounds() {
  return {
    win1: await loadSound(win1SoundPath),
    win2: await loadSound(win2SoundPath),
    loss: await loadSound(lossSoundPath),
    clic: await loadSound(clicSoundPath),
    casino: await loadSound(casinoSoundPath, true),
    dingding: await loadSound(dingdingSoundPath),
    italia: await loadSound(italiaSoundPath),
    blip1: await loadSound(blip1SoundPath),
    blip2: await loadSound(blip2SoundPath),
    bubble1: await loadSound(bubble1SoundPath),
    bubble2: await loadSound(bubble2SoundPath),
    justanOval1: await loadSound(justanOval1SoundPath),
    justanOval2: await loadSound(justanOval2SoundPath),
    fire: await loadSound(fireSoundPath),
  };
}

export type SoundAsset = keyof Awaited<ReturnType<typeof loadSounds>>;

// Image

async function loadImage(path: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = document.createElement("img");

    image.addEventListener("load", () => {
      console.log("Image loaded", path);
      resolve(image);
    });

    image.addEventListener("error", (error) => {
      console.error("Cannot load image", path, error);
      reject(error);
    });

    image.src = path;
  });
}

async function loadImages() {
  return {
    gilbertHead: await loadImage(gilbertHeadImagePath),
    gilbert1: await loadImage(gilbert1ImagePath),
    gilbert2: await loadImage(gilbert2ImagePath),
    gilbert3: await loadImage(gilbert3ImagePath),
    kafkaHead: await loadImage(kafkaHeadImagePath),
    kafka1: await loadImage(kafka1ImagePath),
    kafka2: await loadImage(kafka2ImagePath),
  };
}

export type ImageAsset = keyof Awaited<ReturnType<typeof loadImages>>;

// Video

async function loadVideo(path: string): Promise<HTMLVideoElement> {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");

    video.addEventListener("canplay", () => {
      console.log("Video loaded", path);
      resolve(video);
    });

    video.addEventListener("error", (error) => {
      console.error("Cannot load video", path, error);
      reject(error);
    });

    video.src = path;
    video.load();
  });
}

async function loadVideos() {
  return {
    fire: await loadVideo(fireVideoPath),
    babybel: await loadVideo(babybelVideoPath),
  };
}

export type VideoAsset = keyof Awaited<ReturnType<typeof loadVideos>>;

// Font

async function loadFont(name: string, path: string): Promise<FontFace> {
  return new Promise((resolve, reject) => {
    const font = new FontFace("name", `url("${path}")`);

    font
      .load()
      .then((font) => {
        console.log("Font loaded", name, path);
        document.fonts.add(font);
        resolve(font);
      })
      .catch((error) => {
        console.error("Cannot load font", name, path, error);
        reject(error);
      });
  });
}

async function loadFonts() {
  return {
    text: await loadFont("text", jacquardFontPath),
  };
}

export type FontAsset = keyof Awaited<ReturnType<typeof loadFonts>>;

// All assets
export async function loadAssets() {
  const [sounds, images, videos, fonts] = await Promise.all([
    loadSounds(),
    loadImages(),
    loadVideos(),
    loadFonts(),
    new Promise((r) => setTimeout(r, 3_000)),
  ]);

  return {
    sound: (sound: SoundAsset) => sounds[sound],
    image: (image: ImageAsset) => images[image],
    video: (image: VideoAsset) => videos[image],
    font: (font: FontAsset) => fonts[font],
  };
}

export type Assets = Awaited<ReturnType<typeof loadAssets>>;
